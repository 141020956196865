import React from 'react';
import '../BerichtBezorgingen.scss';
import BerichtBezorgingen from '../BerichtBezorgingen';

const OpdrachtStatusBerichten = () => {
  return (
    <BerichtBezorgingen heading="Opdracht- & Statusberichten" filterOpdracht filterStatus
                        url={'opdracht-statusberichten'} />
  );
};

export default OpdrachtStatusBerichten;