import React from 'react';
import { IBerichtbezorging } from 'interfaces/berichtbezorging';
import { IColumn } from '@fluentui/react';
import './BerichtBezorgingenTable.scss';
import OhkTable from 'components/OhkTable';

interface IBerichtBezorgingTableProps {
  items: IBerichtbezorging[];
  fetchStatus: string;
  onSelectionChanged?: (ids: string[]) => void;
  columns: IColumn[];
}


const BerichtBezorgingenTable: React.FC<IBerichtBezorgingTableProps> = ({
                                                                          items,
                                                                          fetchStatus,
                                                                          onSelectionChanged,
                                                                          columns
                                                                        }) => {

  const handleGetKey = (item): string => {
    return (item as IBerichtbezorging).key;
  };

  return (
    <div className="berichtbezorgingen-table">
      <OhkTable
        disableSort={false}
        columns={columns}
        items={items}
        loading={fetchStatus === 'pending'}
        onGetKey={handleGetKey}
        onSelectionChanged={onSelectionChanged}
      />
    </div>
  );
};

export default BerichtBezorgingenTable;