import React, { useCallback, useEffect, useMemo, useState } from "react";
import { DefaultButton, IColumn, Link } from "@fluentui/react";
import InputCard from "components/InputCard";
import { IAannemer } from "interfaces/aannemer";
import { useAppDispatch, useTypedSelector } from "store";
import { getAannemerEndpointsBezorgen, getAannemers } from "store/selectors/aannemers";
import { getPropertyName } from "lib/interfaceUtils";
import { OpdrachtSoortLabelMap } from "enums/opdrachtSoort";
import { WerkwijzeOptieLabelMap } from "enums/werkwijzeOptie";
import { IWerkwijze } from "interfaces/werkwijze";
import Add from "./Add";
import { fetchAannemerEndpointsBezorgen } from "store/actions/aannemers/endpointsBezorgen";
import OhkTable from "components/OhkTable";
import { GlnCodeType } from "../../../../../interfaces/aannemerGlnType";

interface IWerkwijzeProps {
  aannemer: IAannemer;
}

const Werkwijzes: React.FC<IWerkwijzeProps> = ({ aannemer }) => {
  const dispatch = useAppDispatch();
  const [editWerkwijzeId, setEditWerkwijzeId] = useState<string | undefined>();
  const [addItem, setAddItem] = useState(false);

  const { endpointsBezorgen } = useTypedSelector(getAannemerEndpointsBezorgen);
  const { status } = useTypedSelector(getAannemers);
  const { werkwijzes = [] } = aannemer;

  useEffect(() => {
    dispatch(fetchAannemerEndpointsBezorgen(aannemer.id.toString()));
  }, [dispatch]);

  const handleGetKey = useCallback((item: IWerkwijze) => item.id.toString(), []);

  const editWerkwijze = useMemo(() => {
    if (!editWerkwijzeId) return undefined;
    return werkwijzes.find(x => x.id === +editWerkwijzeId);
  }, [editWerkwijzeId, werkwijzes]);

  const columns = useMemo(
    (): IColumn[] => [
      {
        name: "Opdrachtsoort",
        key: getPropertyName<IWerkwijze>("opdrachtsoort"),
        fieldName: getPropertyName<IWerkwijze>("opdrachtsoort"),
        minWidth: 80,
        maxWidth: 100,
        isResizable: true,
        data: "string",
        onRender: (item: IWerkwijze) => (
          <Link key={item.id} onClick={() => setEditWerkwijzeId(item.id.toString())}>
            {OpdrachtSoortLabelMap[item.opdrachtsoort]}
          </Link>
        )
      },
      {
        name: "Werkwijze",
        key: getPropertyName<IWerkwijze>("werkwijze"),
        fieldName: getPropertyName<IWerkwijze>("werkwijze"),
        minWidth: 80,
        maxWidth: 100,
        isResizable: true,
        data: "string",
        onRender: (item: IWerkwijze) => <span>{WerkwijzeOptieLabelMap[item.werkwijze]}</span>
      },
      {
        name: "Endpoint",
        key: getPropertyName<IWerkwijze>("endpointBezorgingId"),
        fieldName: getPropertyName<IWerkwijze>("endpointBezorgingId"),
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        data: "string",
        onRender: (item: IWerkwijze) => (
          <span>{item.endpointBezorgingId && endpointsBezorgen.find(x => x.id === item.endpointBezorgingId)?.naam}</span>
        )
      }
    ],
    [setEditWerkwijzeId, endpointsBezorgen, aannemer]
  );

  const disabledToevoegen = !Boolean(
    Object.keys(OpdrachtSoortLabelMap)
      .filter(opt => !werkwijzes.find(w => w.opdrachtsoort === opt)).length > 0
  );

  return (
    <>
      <InputCard title="Werkwijze" className="max-width-800" style={{ minWidth: 500, minHeight: 300 }}
                 contentStyle={{ flexGrow: 1 }}>
        <OhkTable
          columns={columns}
          items={werkwijzes}
          loading={status === "pending"}
          onItemInvoked={setEditWerkwijzeId}
          onGetKey={handleGetKey}
          disableScroll={true}
          disableSort={true}
          detailsListStyles={{ headerWrapper: { paddingTop: 0 } }}
        />
        {aannemer.glnCodeType === GlnCodeType.Ketenstandaard &&
          <div className="werkwijze-actions flex-container" style={ActionButtonsStyles}>
            <DefaultButton
              text="Toevoegen werkwijze"
              disabled={disabledToevoegen}
              onClick={() => setAddItem(true)} />
          </div>
        }
      </InputCard>
      {editWerkwijze && (
        <Add aannemer={aannemer} werkwijze={editWerkwijze} onClose={() => setEditWerkwijzeId(undefined)}
             excludeDicoOption={aannemer.glnCodeType !== GlnCodeType.Ketenstandaard} />
      )}
      {addItem && <Add aannemer={aannemer} onClose={() => setAddItem(false)} />}
    </>
  );
};

export default Werkwijzes;


const ActionButtonsStyles: React.CSSProperties = {
  marginTop: "16px",
  justifyContent: "flex-end",
  padding: "8px",
  alignItems: "center"
};