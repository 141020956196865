import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  DefaultButton,
  ITextFieldStyles,
  IToggleStyles,
  MessageBar,
  MessageBarType,
  Spinner,
  SpinnerSize,
  Stack,
  TextField
} from "@fluentui/react";
import InputCard from "components/InputCard";
import { IAannemer, IUpdateAannemerForm, updateAannemerSchema } from "interfaces/aannemer";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { resetUpsertAannemer, updateAannemer } from "store/actions/aannemers/upsert";
import { peekResult, useAppDispatch, useTypedSelector } from "store";
import { getPropertyName } from "lib/interfaceUtils";
import FluentToggle from "components/FluentToggle";
import { getAannemers, getUpsertAannemer } from "store/selectors/aannemers";

interface IDetails {
  aannemer: IAannemer;
}

const Details: React.FC<IDetails> = ({ aannemer }) => {
  const dispatch = useAppDispatch();
  const [dataChanged, setDataChanged] = useState(false);

  const { status, error } = useTypedSelector(getUpsertAannemer);
  const { list: aannemers } = useTypedSelector(getAannemers);

  const defaultValues: IUpdateAannemerForm = useMemo(
    () => ({
      ...aannemer
    }),
    [aannemer]
  );

  const { handleSubmit, control, formState, reset, watch, setValue, errors: formErrors } = useForm<IUpdateAannemerForm>(
    {
      mode: "onBlur",
      reValidateMode: "onBlur",
      resolver: yupResolver(updateAannemerSchema),
      context: {
        aannemerId: aannemer.id,
        aannemers
      },
      defaultValues
    }
  );

  useEffect(() => {
    dispatch(resetUpsertAannemer());
  }, [dispatch]);

  const watchFields = watch();

  useEffect(() => {
    setDataChanged(
      watchFields.geblokkeerd !== defaultValues.geblokkeerd ||
      watchFields.aadDomainName !== defaultValues.aadDomainName ||
      watchFields.referentie !== defaultValues.referentie ||
      watchFields.opdrachtEmailAdres !== defaultValues.opdrachtEmailAdres
    );
  }, [watchFields, defaultValues, setDataChanged]);

  const onSave = useCallback(
    (data: IUpdateAannemerForm) => {
      dispatch(resetUpsertAannemer());
      dispatch(
        updateAannemer({
          id: aannemer.id.toString(),
          data
        })
      ).then(peekResult);
    },
    [dispatch, aannemer]
  );

  const handleSave = () => {
    handleSubmit(onSave)();
  };

  return (
    <InputCard title="Algemeen" className="max-width-800" style={{ minWidth: 500 }}>
      {error && dataChanged && (
        <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
          {error}
        </MessageBar>
      )}
      <TextField autoComplete="off" styles={labelStyles} label="Naam" underlined readOnly value={aannemer.naam} />
      <TextField autoComplete="off" styles={labelStyles} label="GLN-code" underlined readOnly
                 value={aannemer.gln} />
      <TextField autoComplete="off" styles={labelStyles} label="GLN-code type" underlined readOnly
                 value={aannemer.glnCodeType} />
      <form onSubmit={handleSubmit(onSave)}>
        <Controller
          name={getPropertyName<IUpdateAannemerForm>("aadDomainName")}
          control={control}
          render={({ onChange, onBlur, value }) => (
            <TextField
              autoComplete="off"
              label="Azure AD domeinnaam"
              styles={labelStyles}
              underlined
              value={value}
              placeholder=""
              onChange={onChange}
              onBlur={onBlur}
              errorMessage={formErrors.aadDomainName?.message}
            />
          )}
        />
        <Controller
          name={getPropertyName<IUpdateAannemerForm>("referentie")}
          control={control}
          render={({ onChange, onBlur, value }) => (
            <TextField
              autoComplete="off"
              label="Externe referentie"
              styles={labelStyles}
              underlined
              value={value}
              placeholder=""
              onChange={onChange}
              onBlur={onBlur}
              errorMessage={formErrors.referentie?.message}
            />
          )}
        />
        <Controller
          name={getPropertyName<IUpdateAannemerForm>("opdrachtEmailAdres")}
          control={control}
          render={({ onChange, onBlur, value }) => (
            <TextField
              autoComplete="off"
              label="Opdracht email-adres"
              styles={labelStyles}
              underlined
              value={value}
              placeholder=""
              onChange={onChange}
              onBlur={onBlur}
              errorMessage={formErrors.opdrachtEmailAdres?.message}
            />
          )}
        />
        <Controller
          name={getPropertyName<IUpdateAannemerForm>("geblokkeerd")}
          control={control}
          defaultValue={false}
          render={({ onBlur, value }) => (
            <FluentToggle
              label="Geblokkeerd"
              inlineLabel
              styles={toggleStyles}
              checked={value}
              onChange={(_, checked) => {
                setValue("geblokkeerd", checked || false);
                onBlur();
              }}
            />
          )}
        />
        <input type="submit" style={{ visibility: "hidden" }} />
      </form>
      {dataChanged && (
        <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 10 }} styles={{ root: { marginTop: 10 } }}>
          <DefaultButton disabled={status === "pending"} onClick={() => reset()} text="Annuleren" />
          <DefaultButton primary allowDisabledFocus disabled={!formState.isValid} onClick={handleSave}>
            {status === "pending" ? <Spinner size={SpinnerSize.small} /> : "Opslaan"}
          </DefaultButton>
        </Stack>
      )}
    </InputCard>
  );
};

const labelWidth = 175;

const toggleStyles: Partial<IToggleStyles> = {
  label: {
    width: labelWidth,
    paddingLeft: 12
  },
  root: {
    borderBottom: "1px solid #efefef",
    height: 32
  }
};

const labelStyles: Partial<ITextFieldStyles> = {
  wrapper: {
    borderBottom: "1px solid #efefef"
  },
  subComponentStyles: {
    label: {
      root: { width: labelWidth }
    }
  }
};

export default Details;
