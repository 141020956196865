import React from 'react';
import Row from './Row';
import Counter from 'components/Counter';
import { useTypedSelector } from 'store';
import { getStatistiekenPerType } from 'store/selectors/dashboard';
import { Spinner, SpinnerSize } from '@fluentui/react';
import { IDateRange } from '../../../components/DateRangeSelector/IDateRange';

interface IProps {
  dateRange: IDateRange;
}

const CounterPerType: React.FC<IProps> = props => {
  const { data, status } = useTypedSelector(getStatistiekenPerType);

  return (
    <Row title="Berichten per type">
      {status === 'pending' ? (
        <Spinner size={SpinnerSize.large} />
      ) : (
        <>
          <Counter number={data.aantalOpdrachtBerichten} text={'Opdrachtberichten'} />
          <Counter number={data.aantalStatusBerichten} text={'Statusberichten'} />
          <Counter number={data.aantalFactuurBerichten} text={'Factuurberichten'} />
        </>
      )}
    </Row>
  );
};

export default CounterPerType;
