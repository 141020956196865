import React from "react";
import Row from "./Row";
import Counter from "components/Counter";
import { useTypedSelector } from "store";
import { getStatistiekenPerStatus } from "store/selectors/dashboard";
import { SharedColors, Spinner, SpinnerSize } from "@fluentui/react";
import { IDateRange } from "components/DateRangeSelector/IDateRange";
import { useHistory } from "react-router-dom";
import { Berichtstatus } from "enums/berichtStatus";
import primaryTheme from "../../../themes/primary";
import UseRoles from "../../../services/UseRoles";

interface IProps {
  dateRange: IDateRange;
}

const CounterPerStatusEnTotaal: React.FC<IProps> = props => {
  const { data, status } = useTypedSelector(getStatistiekenPerStatus);
  const history = useHistory();
  const {isBeheerder} = UseRoles();

  function onClickTotaal(): any {
    history.push("/berichten");
  }

  return (
    <>
      <Row>
        {status === "pending" ? (
          <Spinner size={SpinnerSize.large} />
        ) : (
          <div className="statistiek-alle flex-grow flex-container flex-gap-16">
            <Counter number={data.alleBerichten} text={"Totaal berichten"}
                     onClick={isBeheerder ? onClickTotaal : undefined} />
          </div>
        )}
      </Row>
      <Row title="Berichten per status">
        {status === "pending" ? (
          <Spinner size={SpinnerSize.large} />
        ) : (
          <div className="statistiek-perstatus flex-grow flex-container flex-gap-16">
            <Counter
              number={data.onbestelbaar}
              text={"Onbestelbaar"}
              borderBottomColor={data.onbestelbaar > 0 ? SharedColors.red10 : primaryTheme.palette.green}
              onClick={isBeheerder ? () => history.push(`/berichten/${Berichtstatus.Onbestelbaar}`) : undefined}
            />
            <Counter number={data.inBehandeling}
                     text={"In behandeling"}
                     borderBottomColor={data.inBehandeling > 0 ? SharedColors.orange10 : ""}
                     onClick={isBeheerder ? () => history.push(`/berichten/${Berichtstatus.InBehandeling}`) : undefined}
            />
            <Counter number={data.nietBezorgd}
                     text={"Niet bezorgd"}
                     borderBottomColor={data.nietBezorgd > 0 ? SharedColors.red10 : primaryTheme.palette.green}
                     onClick={isBeheerder ? () => history.push(`/berichten/${Berichtstatus.NietBezorgd}`) : undefined}
            />
            <Counter number={data.bezorgd}
                     text={"Bezorgd"}
                     borderBottomColor={data.bezorgd > 0 ? primaryTheme.palette.green : ""}
                     onClick={isBeheerder ? () => history.push(`/berichten/${Berichtstatus.Bezorgd}`) : undefined} />
            <Counter number={data.handmatigAfgehandeld}
                     text={"Handmatig afgehandeld"}
                     borderBottomColor={data.handmatigAfgehandeld > 0 ? SharedColors.orange10 : ""}
                     onClick={isBeheerder ? () => history.push(`/berichten/${Berichtstatus.HandmatigAfgehandeld}`) : undefined}
            />
          </div>
        )}
      </Row>
    </>
  );
};

export default CounterPerStatusEnTotaal;
