import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import './StatusTabs.scss';
import { Pivot, PivotItem } from '@fluentui/react';
import pivotStyles from 'styles/pivotStyles';
import { Constants } from 'lib/constants/icons';
import { Berichtstatus } from '../../../../enums/berichtStatus';
import { IBerichtbezorging } from '../../../../interfaces/berichtbezorging';

interface StatusTabProps {
  statusFilter: Berichtstatus;
  items: IBerichtbezorging[];
  filteredItems: IBerichtbezorging[];
  onStatusFilterChanged: (filter: string) => void;
}

const StatusTabs: React.FC<StatusTabProps> = ({ statusFilter, items, onStatusFilterChanged, filteredItems }) => {

  function onLinkClick(item?: PivotItem, ev?: React.MouseEvent<HTMLElement>): any {
    onStatusFilterChanged(item?.props?.itemKey!);
  }

  let selectedKey = useMemo(() => {
    return statusFilter.toString();
  }, [statusFilter]);

  const calculateCounts = (data: IBerichtbezorging[]) => ({
    0: data.length,
    1: data.filter(item => item.statusId === 1).length,
    2: data.filter(item => item.statusId === 2).length,
    3: data.filter(item => item.statusId === 3).length,
    4: data.filter(item => item.statusId === 4).length,
    5: data.filter(item => item.statusId === 5).length
  });

  const [initialCounts, setInitialCounts] = useState(calculateCounts(items));

  const [filteredCounts, setFilteredCounts] = useState(initialCounts);

  useEffect(() => {
    setInitialCounts(calculateCounts(items));
    setFilteredCounts(calculateCounts(items));
  }, [items]);

  useEffect(() => {
    setFilteredCounts(calculateCounts(filteredItems));
  }, [filteredItems]);

  const activeTabCount = filteredCounts[selectedKey];

  return (
    <Pivot styles={pivotStyles} headersOnly={true} onLinkClick={onLinkClick} selectedKey={selectedKey}>
      <PivotItem itemKey="0" headerText="Alle berichten" itemIcon={Constants.IconBerichtStatusAlles}
                 itemCount={selectedKey === '0' ? activeTabCount : initialCounts[0]} />
      <PivotItem itemKey="1" headerText="Onbestelbaar" itemIcon={Constants.IconBerichtStatusOnbestelbaar}
                 itemCount={selectedKey === '1' ? activeTabCount : initialCounts[1]} />
      <PivotItem itemKey="2" headerText="In behandeling" itemIcon={Constants.IconBerichtStatusInBehandeling}
                 itemCount={selectedKey === '2' ? activeTabCount : initialCounts[2]} />
      <PivotItem itemKey="4" headerText="Niet bezorgd" itemIcon={Constants.IconBerichtStatusNietBezorgd}
                 itemCount={selectedKey === '4' ? activeTabCount : initialCounts[4]} />
      <PivotItem itemKey="3" headerText="Bezorgd" itemIcon={Constants.IconBerichtStatusBezorgd}
                 itemCount={selectedKey === '3' ? activeTabCount : initialCounts[3]} />
      <PivotItem itemKey="5" headerText="Handmatig afgehandeld"
                 itemIcon={Constants.IconBerichtStatusHandmatigAfgehandeld}
                 itemCount={selectedKey === '5' ? activeTabCount : initialCounts[5]} />
    </Pivot>

  );
};

export default StatusTabs;