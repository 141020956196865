import React from 'react';
import AfhandelenNietBezorgd from '../AfhandelenNietBezorgd';

const NietBezorgdeBerichten: React.FC = () => {

  return (
    <AfhandelenNietBezorgd pageHeading="Niet bezorgde berichten" />
  );
};

export default NietBezorgdeBerichten;