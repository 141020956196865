import React from 'react';
import AfhandelenNietBezorgd from '../AfhandelenNietBezorgd';

const AfhandelenStatusBerichten: React.FC = () => {

  return (
    <AfhandelenNietBezorgd pageHeading="Statusberichten" fieldName={'opdrachtStatus'} columnTitle={'OpdrachtStatus'}
                           columnMinWidth={160} columnMaxWidth={180}
                           filterStatus />
  );
};

export default AfhandelenStatusBerichten;