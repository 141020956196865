import React from "react";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";

import { useIsAuthenticated } from "@azure/msal-react";

import Main from "./Main";
import NotFound from "./components/NotFound";
import Login from "./containers/Login";
import Callback from "./containers/Callback";
import Aannemers from "./containers/pages/Aannemers";
import Aannemer from "containers/pages/Aannemers/Aannemer";
import Instellingen from "containers/pages/Instelllingen";
import Dashboard from "containers/pages/Dashboard";
import Mutatielog from "./containers/pages/Mutatielog/Mutatielog";
import MutatieLogItem from "./containers/pages/Mutatielog/MutatielogItem/MutatieLogItem";
import { Importeren } from "./containers/pages/Aannemers/Importeren/Importeren";
import BusinessRules from "./containers/pages/BusinessRules";
import FactuurBerichten from "./containers/pages/BerichtBezorgingen/FactuurBerichten/FactuurBerichten";
import OpdrachtStatusBerichten from "./containers/pages/BerichtBezorgingen/Opdracht & Status Berichten";
import AlleBerichten from "./containers/pages/BerichtBezorgingen/AlleBerichten";
import AfhandelenFactuurBerichten from './containers/pages/AfhandelenNietBezorgd/FactuurBerichten';
import NietBezorgdeBerichten from './containers/pages/AfhandelenNietBezorgd/Niet bezorgde berichten';
import AfhandelenStatusBerichten from './containers/pages/AfhandelenNietBezorgd/StatusBerichten';
import AfhandelenOpdrachtBerichten from "./containers/pages/AfhandelenNietBezorgd/OpdrachtBerichten";

const AppRouter = () => {
  const isAuthenticated = useIsAuthenticated();

  return (
    <Router>
      <Switch>
        <Route path="/login" render={() => (isAuthenticated ? <Redirect to="/callback" /> : <Login />)} />
        <Route path="/callback" component={Callback} />
        <Route
          render={() =>
            isAuthenticated ? (
              <Main>
                <Switch>
                  <Route path="/" exact render={() => <Redirect to="/dashboard" />} />
                  <Route path="/corporatie" exact component={Instellingen} />
                  <Route path="/aannemers" exact component={Aannemers} />
                  <Route path="/aannemers/importeren" exact component={Importeren} />
                  <Route path="/aannemers/:aannemerId" exact component={Aannemer} />
                  <Route path="/berichten/:status" exact component={AlleBerichten} />
                  <Route path="/berichten/" component={AlleBerichten} />
                  <Route path="/factuurberichten/:status" exact component={FactuurBerichten} />
                  <Route path="/factuurberichten/" component={FactuurBerichten} />
                  <Route path="/opdracht-statusberichten/:status" exact component={OpdrachtStatusBerichten} />
                  <Route path="/opdracht-statusberichten/" component={OpdrachtStatusBerichten} />
                  <Route path="/businessrules/" component={BusinessRules} />
                  <Route path="/dashboard" exact component={Dashboard} />
                  <Route path="/afhandelen/nietbezorgd" exact component={NietBezorgdeBerichten} />
                  <Route path="/afhandelen/factuurberichten" exact component={AfhandelenFactuurBerichten} />
                  <Route path="/afhandelen/statusberichten" exact component={AfhandelenStatusBerichten} />
                  <Route path="/afhandelen/opdrachtberichten" exact component={AfhandelenOpdrachtBerichten} />
                  <Route path="/mutatielog" exact component={Mutatielog} />
                  <Route path="/mutatielog/:mutatielogId" exact component={MutatieLogItem} />
                  <Route component={NotFound} />
                </Switch>
              </Main>
            ) : (
              <Redirect to="/login" />
            )
          }
        />
      </Switch>
    </Router>
  );
};

export default AppRouter
