import React from 'react';
import AfhandelenNietBezorgd from '../AfhandelenNietBezorgd';

const AfhandelenFactuurBerichten: React.FC = () => {

  return (
    <AfhandelenNietBezorgd pageHeading="Factuurberichten" fieldName={'factuurNummer'} columnTitle={'Factuur Nummer'}
                           columnMinWidth={100} columnMaxWidth={140}
                           filterFactuur />
  );
};

export default AfhandelenFactuurBerichten;