import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IStateMachine, RootState } from '../../index';
import { invokeFetch } from 'services/apiClient';
import { BerichtBezorgingRequest, IBerichtbezorging } from 'interfaces/berichtbezorging';

interface IBerichtbezoringState extends IStateMachine {
  list: Array<IBerichtbezorging>;
}

const initState: IBerichtbezoringState = {
  list: [],
  status: 'idle',
  error: ''
};

const fetchBerichtbezorgingMetFilter = createAsyncThunk<Array<IBerichtbezorging>, BerichtBezorgingRequest, {
  state: RootState
}>(
  'berichtbezorging/fetchMetFilter',
  async (request, thunkApi) => {
    var body = {
      DatumVan: request.datumVan,
      DatumTm: request.datumTm,
      filterFactuur: request.filterFactuur,
      filterOpdracht: request.filterOpdracht,
      filterStatus: request.filterStatus
    };
    return await invokeFetch<Array<IBerichtbezorging>>(
      thunkApi,
      'POST',
      `/berichtbezorgingen`,
      body,
      false
    );
  }
);

const fetchBerichtbezorgingAfhandelenMetFilter = createAsyncThunk<Array<IBerichtbezorging>, BerichtBezorgingRequest, {
  state: RootState
}>(
  'berichtbezorging/fetchAfhandelenFilter',
  async (request, thunkApi) => {
    var body = {
      DatumVan: request.datumVan,
      DatumTm: request.datumTm,
      filterFactuur: request.filterFactuur,
      filterOpdracht: request.filterOpdracht,
      filterStatus: request.filterStatus
    };
    return await invokeFetch<Array<IBerichtbezorging>>(
      thunkApi,
      'POST',
      `/berichtbezorgingen/nietbezorgd`,
      body,
      false
    );
  }
);

export const slice = createSlice({
  name: 'berichtbezorging/data',
  initialState: initState,
  reducers: {},
  extraReducers: builder => {

    builder.addCase(fetchBerichtbezorgingMetFilter.pending, (state) => {
      state.status = 'pending';
      state.error = '';
    });
    builder.addCase(fetchBerichtbezorgingMetFilter.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.list = action.payload;
    });
    builder.addCase(fetchBerichtbezorgingMetFilter.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || '';
    });
    builder.addCase(fetchBerichtbezorgingAfhandelenMetFilter.pending, (state) => {
      state.status = 'pending';
      state.error = '';
    });
    builder.addCase(fetchBerichtbezorgingAfhandelenMetFilter.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.list = action.payload;
    });
    builder.addCase(fetchBerichtbezorgingAfhandelenMetFilter.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || '';
    });
  }
});

export { fetchBerichtbezorgingMetFilter, fetchBerichtbezorgingAfhandelenMetFilter };

export default slice.reducer;