import React, {useCallback, useEffect, useMemo, useState} from 'react';
import '../BerichtBezorgingen.scss';
import BerichtBezorgingenTable from '../BerichtbezorgingenTable/BerichtbezorgingenTable';
import {useAppDispatch, useTypedSelector} from 'store';
import {getBerichtBezorgingen} from 'store/selectors/berichtbezorgingen';
import {fetchBerichtbezorgingMetFilter} from 'store/actions/berichtbezorging/data';
import commandBarStyles from 'styles/commandBarStyles';
import {
  CommandBar,
  FontIcon,
  IColumn,
  ICommandBarItemProps,
  IconButton,
  Link,
  ResponsiveMode,
  TooltipHost
} from '@fluentui/react';
import {Berichtstatus} from 'enums/berichtStatus';
import StatusTabs from '../StatusTabs/StatusTabs';
import {useHistory, useParams} from 'react-router-dom';
import OhkModal from 'components/OhkModal';
import {EndpointInfoRequest} from 'interfaces/EndpointInfoRequest';
import {EndpointType} from 'enums/endpointType';
import EndpointInformation from 'components/EndpointInformation/EndpointInformation';
import DateRangeSelector from 'components/DateRangeSelector/DateRangeSelector';
import {BerichtBezorgingRequest, IBerichtbezorging} from 'interfaces/berichtbezorging';
import {Zoeken} from '../Zoeken/Zoeken';
import {BerichtZoekTerm} from '../Zoeken/BerichtZoekTerm';
import {ZoekVelden} from '../Zoeken/ZoekVelden';
import {getPropertyName} from '../../../../lib/interfaceUtils';
import {stringToLocaleString} from '../../../../lib/internationalization';
import {
  FilterDropdown,
  getDropdownOptions,
  iconStatusClass,
  statusFieldClass,
  statusIcon
} from '../BerichtbezorgingenTable/utils';
import { fetchBlob } from '../../../../store/actions/blobs/data';
import { useDateRangeFilter } from '../../AfhandelenNietBezorgd/Hooks/useDateRangeFilter';

interface RouterParams {
  status: string;
}

const FactuurBerichten = () => {
  const dispatch = useAppDispatch();
  const [refetch, setRefetch] = useState(false);
  const [statusFilter, setStatusFilter] = useState(Berichtstatus.Geen);
  const { list: items, status: fetchStatus } = useTypedSelector(getBerichtBezorgingen);
  const history = useHistory();
  const [EndpointInfoRequest, setEndpointInfoRequest] = useState<EndpointInfoRequest>();
  const { status } = useParams<RouterParams>();
  const [zoekTermFilter, setZoekTermFilter] = useState<BerichtZoekTerm>({
    zoekTerm: '',
    veld: ZoekVelden.opdrachtnummer
  });
  const [filters, setFilters] = useState<Record<string, string | undefined>>({});

  const { dateRange, dateFilterRange, onDateRangeChanged } = useDateRangeFilter();

  const handleResetFilters = () => {
    setFilters({});
  };  

  // Luister naar de status die in de url staat bijv: /berichten/2, voor status 2
  // Zet de statusFilter op deze status
  useEffect(() => {
    const berichtStatus = status ? parseInt(status) : Berichtstatus.Geen;
    setStatusFilter(berichtStatus);
  }, [status]);


  useEffect(() => {
    if (refetch) {
      retrieveData();
      setRefetch(false);
    }
  }, [dispatch, refetch]);

  useEffect(() => {
    retrieveData();
  }, [dispatch, dateFilterRange]);
  const retrieveData = () => {
    const request = {
      datumVan: dateRange.start,
      datumTm: dateRange.end,
      filterFactuur: true
    } as BerichtBezorgingRequest;
    var fetchPromise = dispatch(fetchBerichtbezorgingMetFilter(request));
    return () => {
      fetchPromise.abort();
    };
  };

  const commandBarItems: ICommandBarItemProps[] = useMemo(
    () => [
      {
        key: 'pagetitle',
        onRender: () => (
          <span className="page-title">Factuurberichten</span>
        )
      }
    ],
    []
  );

  const commandBarItemsFar: ICommandBarItemProps[] = useMemo(
    () => [
      {
        key: 'resetFilters',
        text: 'Alle filters wissen',
        iconProps: { iconName: 'Clear', className: 'icon' },
        split: false,
        ariaLabel: 'Alle filters wissen',
        disabled: Object.keys(filters).length === 0,
        onClick: handleResetFilters,
        className: 'refresh-button'
      },
      {
        key: 'refresh',
        text: 'Vernieuwen',
        iconProps: { iconName: 'Refresh', className: 'icon' },
        split: false,
        ariaLabel: 'Vernieuwen',
        onClick: () => setRefetch(true),
        className: 'refresh-button'
      }
    ],
    [setRefetch]
  );

  function onStatusFilterChanged(filter: string) {
    handleResetFilters();
    if (filter === '0') {
      history.push(`/factuurberichten`);
    } else {
      history.push(`/factuurberichten/${filter}`);
    }
  }

  function onEndpointVanClicked(id: number): any {
    setEndpointInfoRequest({ endpointType: EndpointType.Ontvangst, endpointId: id });
  }

  function onEndpointNaarClicked(id: number): any {
    setEndpointInfoRequest({ endpointType: EndpointType.Bezorging, endpointId: id });
  }

  const handleFilterChange = (filterKey: string, selectedKey: string | undefined) => {
    setFilters((prevFilters) => {
      if (selectedKey === '0') {
        return {
          ...prevFilters,
          [filterKey]: ''
        };
      }

      return {
        ...prevFilters,
        [filterKey]: selectedKey
      };
    });
  };

  const statusOptions = useMemo(() => getDropdownOptions(items, 'status'), [items]);
  const vanOptions = useMemo(() => getDropdownOptions(items, 'van'), [items]);
  const naarOptions = useMemo(() => getDropdownOptions(items, 'naar'), [items]);

  const handleBlobDownload = useCallback(
    (blobId: string | undefined, soort: 'ontvangen-bericht' | 'response-ontvangst' | 'bezorgd-bericht' | 'response-bezorging') => {
      if (!blobId) return;

      const fileName = `${soort}-${blobId}.xml`;
      dispatch(fetchBlob({ id: blobId, fileName })).then();
    },
    [dispatch]
  );

  function handleVanNaarClick(id: number, isVan: boolean) {
    if (isVan) {
      onEndpointVanClicked?.(id);
    } else {
      onEndpointNaarClicked?.(id);
    }
  }

  const factuurColumns: IColumn[] = useMemo(() =>
      [
        {
          key: 'iconStatus',
          name: 'Status icon',
          className: 'status-field',
          iconClassName: 'header-status-field-icon',
          iconName: 'Page',
          isIconOnly: true,
          minWidth: 24,
          maxWidth: 24,
          onRender: (item: IBerichtbezorging) => (
            <div className={statusFieldClass(item.statusId)}><FontIcon aria-label="status icon"
                                                                       iconName={statusIcon(item.statusId)}
                                                                       className={iconStatusClass} /></div>
          )
        },
        {
          key: getPropertyName<IBerichtbezorging>('ontvangen'),
          name: 'Ontvangen',
          fieldName: getPropertyName<IBerichtbezorging>('ontvangen'),
          minWidth: 80,
          maxWidth: 100,
          isResizable: true,
          data: 'date',
          onRender: (item: IBerichtbezorging) => (
            <span>{item.ontvangen && stringToLocaleString(item.ontvangen)}</span>
          )
        },
        {
          key: getPropertyName<IBerichtbezorging>('factuurNummer'),
          name: 'Factuurnummer',
          fieldName: getPropertyName<IBerichtbezorging>('factuurNummer'),
          minWidth: 100,
          maxWidth: 140,
          isResizable: true,
          data: 'string'
        },
        {
          key: getPropertyName<IBerichtbezorging>('opdracht'),
          name: 'Opdrachtnummer',
          fieldName: getPropertyName<IBerichtbezorging>('opdracht'),
          minWidth: 100,
          maxWidth: 140,
          isResizable: true,
          data: 'string'
        },
        {
          key: getPropertyName<IBerichtbezorging>('status'),
          name: 'Status',
          fieldName: getPropertyName<IBerichtbezorging>('status'),
          minWidth: 160,
          maxWidth: 150,
          isResizable: true,
          data: 'string',
          onRenderHeader: () => (
            <FilterDropdown
              filterKey="status"
              placeholder="Status"
              selectedKey={filters['status']}
              options={statusOptions}
              onFilterChange={handleFilterChange}
            />
          )
        },
        {
          key: getPropertyName<IBerichtbezorging>('laatsteMutatie'),
          name: 'Laatste mutatie',
          fieldName: getPropertyName<IBerichtbezorging>('laatsteMutatie'),
          minWidth: 110,
          maxWidth: 130,
          isResizable: true,
          data: 'date',
          onRender: (item: IBerichtbezorging) => (
            <span>{item.laatsteMutatie && stringToLocaleString(item.laatsteMutatie)}</span>
          )
        },
        {
          key: getPropertyName<IBerichtbezorging>('van'),
          name: 'Van',
          fieldName: getPropertyName<IBerichtbezorging>('van'),
          minWidth: 150,
          maxWidth: 160,
          isResizable: true,
          data: 'string',
          onRender: (item: IBerichtbezorging) => {
            const hasVanApp = !!item.vanId;
            return (
              <Link
                key={item.berichtId}
                onClick={() => (hasVanApp && !!item.vanId ? handleVanNaarClick(item.vanId, true) : undefined)}
              >{item.van}</Link>
            );
          },
          onRenderHeader: () => (
            <FilterDropdown
              filterKey="van"
              placeholder="Van"
              selectedKey={filters['van']}
              options={vanOptions}
              onFilterChange={handleFilterChange}
            />
          )
        },
        {
          key: getPropertyName<IBerichtbezorging>('naar'),
          name: 'Naar',
          fieldName: getPropertyName<IBerichtbezorging>('naar'),
          minWidth: 180,
          maxWidth: 200,
          isResizable: true,
          data: 'string',
          onRender: (item: IBerichtbezorging) => {
            const hasNaarApp = item.naarId;
            return (
              <>
                {item.naarId && item.naarId > 0 ?
                  <Link
                    key={item.berichtId}
                    onClick={() => (hasNaarApp && !!item.naarId ? handleVanNaarClick(item.naarId, false) : undefined)}
                  >{item.naar}</Link>
                  :
                  <span>{item.naar}</span>
                }
              </>
            );
          },
          onRenderHeader: () => (
            <FilterDropdown
              filterKey="naar"
              placeholder="Naar"
              selectedKey={filters['naar']}
              options={naarOptions}
              onFilterChange={handleFilterChange}
            />
          )
        },
        {
          key: getPropertyName<IBerichtbezorging>('toelichting'),
          name: 'Toelichting',
          fieldName: getPropertyName<IBerichtbezorging>('toelichting'),
          minWidth: 80,
          maxWidth: 600,
          isResizable: true,
          data: 'string',
          onRender: (item: IBerichtbezorging) => {
            return (
              <TooltipHost
                content={item.toelichting ?? ''}
                id="tooltip-toelichting"
              >
                <span>{item.toelichting}</span>
              </TooltipHost>
            );
          }
        },
        {
          key: 'origineelbericht',
          name: '',
          minWidth: 20,
          maxWidth: 20,
          isResizable: false,
          onRender: (item: IBerichtbezorging) => (
            <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', height: 'auto' }}>
              <IconButton
                styles={{
                  root: { height: 'auto', ':active': { background: '#ffffff' } },
                  splitButtonContainer: { width: 'auto' }
                }}
                iconProps={{ iconName: 'MoreVertical' }}
                menuProps={{
                  items: [
                    {
                      key: 'berichtinfo',
                      text: `Bericht: ${item.berichtId.toString()}-${item.bezorgingId.toString()}`,
                      disabled: true
                    },
                    {
                      key: 'downloadontvangstbericht',
                      text: 'Ontvangen bericht',
                      iconProps: { iconName: 'Download' },
                      disabled: !item.ontvangenBerichtId,
                      onClick: () => handleBlobDownload(item.ontvangenBerichtId, 'ontvangen-bericht')
                    },
                    {
                      key: 'downloadontvangstResponse',
                      text: 'Response ontvangst',
                      iconProps: { iconName: 'Download' },
                      disabled: !item.ontvangenBerichtResponseId,
                      onClick: () => handleBlobDownload(item.ontvangenBerichtResponseId, 'response-ontvangst')
                    },
                    {
                      key: 'downloadbezorgdbericht',
                      text: 'Bezorgd bericht',
                      iconProps: { iconName: 'Download' },
                      disabled: !item.bezorgdBerichtId,
                      onClick: () => handleBlobDownload(item.bezorgdBerichtId, 'bezorgd-bericht')
                    },
                    {
                      key: 'downloadbezordResponse',
                      text: 'Response bezorging',
                      iconProps: { iconName: 'Download' },
                      disabled: !item.bezorgdBerichtResponseId,
                      onClick: () => handleBlobDownload(item.bezorgdBerichtResponseId, 'response-bezorging')
                    },
                    {
                      key: 'httpResponseCode',
                      text: item.bezorgingHttpStatusCode ? `HTTP response status code bezorging: ${item?.bezorgingHttpStatusCode}` : '',
                      disabled: true
                    }
                  ]
                }}
                hidden={true}
                menuIconProps={{ iconName: '' }}
              />
            </div>
          )
        }
      ],
    [handleVanNaarClick]);

  const filteredItems = useMemo(() => {
    return items
      .filter(item => (statusFilter === 0 || item.statusId === statusFilter))
      .filter((item) => !filters['status'] || item.status === filters['status'])
      .filter((item) => !filters['opdrachtStatus'] || item.opdrachtStatus === filters['opdrachtStatus'])
      .filter((item) => !filters['werkwijze'] || item.werkwijze === filters['werkwijze'])
      .filter((item) => !filters['soort'] || item.soort === filters['soort'])
      .filter((item) => !filters['van'] || item.van === filters['van'])
      .filter((item) => !filters['naar'] || item.naar === filters['naar'])
      .filter((item) => {
        if (!zoekTermFilter?.zoekTerm?.trim()) return true;
        const term = zoekTermFilter.zoekTerm.trim().toLowerCase();
        return zoekTermFilter.veld === ZoekVelden.opdrachtnummer
          ? item.opdracht?.toLowerCase().includes(term)
          : true;
      });
  }, [items, filters, zoekTermFilter, statusFilter]);

  return (
    <div className="berichtbezorgingen">
      <div className="berichtbezorgingen-header">
        <CommandBar items={commandBarItems} farItems={commandBarItemsFar} styles={commandBarStyles} />
        <div className="berichtbezorgingen-header-actions">
          <div className="berichtbezorgingen-header-actions-top flex-container flex-gap-16">
            <DateRangeSelector dateRange={dateRange} onDateRangeChange={onDateRangeChanged}></DateRangeSelector>
            <Zoeken zoekTerm={zoekTermFilter}
                    onZoekTermChanged={zoekTermChanged => setZoekTermFilter(zoekTermChanged)}></Zoeken>
          </div>
          <div className="berichtbezorgingen-header-actions-bottom">
            <StatusTabs statusFilter={statusFilter} items={items}
                        onStatusFilterChanged={onStatusFilterChanged} filteredItems={filteredItems}></StatusTabs>
          </div>
        </div>
      </div>
      <div className="berichtbezorgingen-content">
        <BerichtBezorgingenTable items={filteredItems}
                                 fetchStatus={fetchStatus}
                                 columns={factuurColumns}
        />
      </div>
      {EndpointInfoRequest &&
        <OhkModal title="Endpoint informatie"
                  isOpen={!!EndpointInfoRequest}
                  onClose={() => setEndpointInfoRequest(undefined)}
                  onDismiss={() => setEndpointInfoRequest(undefined)}
                  responsiveMode={ResponsiveMode.large}>
          <EndpointInformation endpointInfoRequest={EndpointInfoRequest}></EndpointInformation>
        </OhkModal>
      }
    </div>
  );
};

export default FactuurBerichten;