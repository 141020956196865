import React from 'react';
import AfhandelenNietBezorgd from '../AfhandelenNietBezorgd';

const AfhandelenOpdrachtBerichten: React.FC = () => {

  return (
    <AfhandelenNietBezorgd pageHeading="Opdrachtberichten" fieldName={'werkwijze'} columnTitle={'Werkwijze'}
                           columnMinWidth={150}
                           columnMaxWidth={160}
                           filterOpdracht columnPlaceholder="Werkwijze" />
  );
};

export default AfhandelenOpdrachtBerichten;