import React from 'react';
import BerichtBezorgingen from '../BerichtBezorgingen';

const AlleBerichten: React.FC = () => {

  return (
    <BerichtBezorgingen heading="Alle berichten" url={'berichten'} />
  );
};

export default AlleBerichten;