import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./index.scss";
import { INavLinkGroup, INavStyles, IRenderFunction, IRenderGroupHeaderProps, Nav, Text } from "@fluentui/react";
import { IRouteGroup, routes } from "./routing";
import { AvailableRoles } from "../../services/UseRoles";
import { fetchUserRoles, selectAll } from "../../store/actions/app/roles";
import { useSelector } from "react-redux";
import { useAppDispatch, useTypedSelector } from 'store';
import { getStatistiekenPerStatus, getStatistiekenPerType } from "../../store/selectors/dashboard";
import { useDateRangeFilter } from "../pages/AfhandelenNietBezorgd/Hooks/useDateRangeFilter";
import { fetchStatistiekenPerType } from "../../store/actions/dashboard/statistiekenPerType";
import { fetchStatistiekenPerStatus } from "../../store/actions/dashboard/statistiekenPerStatus";

const navStyles: Partial<INavStyles> = {
  root: {
    paddingTop: "20px",
    height: "100%",
    boxSizing: "border-box",
    border: "1px solid #eee",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column"
  },
  group: {
    width: "100%",
    h3: {
      paddingLeft: "5px"
    }
  }
};

const onRenderGroupHeader: IRenderFunction<IRenderGroupHeaderProps> = props =>
  props ? (
    <Text variant="large" styles={{ root: { fontWeight: "bold" } }} as="h3">
      {props.name}
    </Text>
  ) : null;

const Navbar: React.FC = () => {
  const history = useHistory();
  const roles = useSelector(selectAll);
  const dispatch = useAppDispatch();
  const { data } = useTypedSelector(getStatistiekenPerType);
  const { data: nietBezorgdData } = useTypedSelector(getStatistiekenPerStatus);
  const { dateRange, dateFilterRange } = useDateRangeFilter();

  const itemCounts = {
    Berichten: nietBezorgdData.alleBerichten,
    Factuurberichten: data.aantalFactuurBerichten,
    OpdrachtStatusBerichten: data.aantalOpdrachtBerichten + data.aantalStatusBerichten,
    AfhandelenFactuurberichten: nietBezorgdData.aantalNietBezorgdFactuur,
    AfhandelenStatusberichten: nietBezorgdData.aantalNietBezorgdStatus,
    AfhandelenOpdrachtberichten: nietBezorgdData.aantalNietBezorgdOpdracht,
    AlleBerichtenNietBezorgeBerichten: nietBezorgdData.nietBezorgd
  };

  useEffect(() => {
    const fetchPromise = dispatch(fetchStatistiekenPerType(dateRange));
    const fetchPerStatus = dispatch(fetchStatistiekenPerStatus(dateRange));

    return () => {
      fetchPromise.abort();
      fetchPerStatus.abort();
    };
  }, [dispatch, dateFilterRange]);

  useEffect(() => {
    dispatch(fetchUserRoles());
  }, []);

  const userHasAccess = (allowedRoles: (keyof typeof AvailableRoles)[]) => {
    return (
      allowedRoles?.some((allowedRole) =>
        roles?.some((userRole) => userRole.id === allowedRole)
      )
    );
  };

  const filteredRoutes = routes.filter(route => {
    return userHasAccess(route.allowedRoles);
  });

  const filteredLinks = (routeGroup: IRouteGroup) => {
    return routeGroup.links
      .filter((link) => userHasAccess(link.allowedRoles))
      .map((link) => {
        const itemCount = itemCounts[link.key];
        return itemCount !== undefined
          ? { ...link, name: `${link.name} (${itemCount})` }
          : link;
      });
  };

  function onRenderLink(link: any, originalRender: any): any {
    return <div data-test={link.automationId}>{originalRender(link)}</div>;
  }

  return <Nav className="nav" styles={navStyles} data-test="data-navbar" onRenderGroupHeader={onRenderGroupHeader}
              onRenderLink={onRenderLink}
              groups={filteredRoutes.map((route) => {
                if ("links" in route) {
                  return {
                    name: route.name,
                    links: filteredLinks(route).map((link) => {
                      return {
                        name: link.name,
                        key: link.key,
                        onClick: () => history.push(link.routePath),
                        automationId: link.automationId
                      };
                    })
                  } as INavLinkGroup;
                } else {
                  return {} as INavLinkGroup;
                }
              })} />;
};

export default Navbar;
