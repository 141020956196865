import { useSelector } from "react-redux";
import { selectAll } from "../store/actions/app/roles";

export const AvailableRoles = {
    Beheerder: "Beheerder",
    Financieel: "Financieel",
    DagelijksOnderhoud: "DagelijksOnderhoud",
}
export const AllRoles: (keyof typeof AvailableRoles)[] = ['Beheerder', 'Financieel', 'DagelijksOnderhoud'];

export const useAssignedRoles = (): (keyof typeof AvailableRoles)[] => {
    const roles = useSelector(selectAll)

    const result: (keyof typeof AvailableRoles)[] = []

    for (const role of Object.keys(AvailableRoles)) {
        if (roles.filter((s) => s.id === AvailableRoles[role]).length > 0) result.push(role as keyof typeof AvailableRoles)
    }
    return result
}

const UseRoles = () => {
    const roles = useSelector(selectAll)

    return {
        isBeheerder: roles.filter((s) => s.id === AvailableRoles.Beheerder).length > 0,
        isFinancieel: roles.filter((s) => s.id === AvailableRoles.Financieel).length > 0,
        isDagelijksOnderhoud: roles.filter((s) => s.id === AvailableRoles.DagelijksOnderhoud).length > 0,
    }
}

export default UseRoles