import { createAsyncThunk, createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { invokeFetch } from 'services/apiClient'
import { entityAdapterInitState } from 'interfaces/entityAdapterState'
import { RootState } from 'store'

export interface IRole {
    id: string
}

const entityAdapter = createEntityAdapter<IRole>()

export const fetchUserRoles = createAsyncThunk('user/fetchRoles', async (_, thunkAPI) => {
    return await invokeFetch<string[]>(thunkAPI, 'GET', '/user/roles')
})

const getSliceState = (state: RootState) => state.app.roles

export const userRolesSlice = createSlice({
    name: 'roles',
    initialState: entityAdapter.getInitialState(entityAdapterInitState),

    reducers: {
    },

    extraReducers: builder => {
        builder.addCase(fetchUserRoles.pending, (state) => {
            state.status = 'pending'
            state.error = ''
        })
        builder.addCase(fetchUserRoles.fulfilled, (state, action: PayloadAction<string[]>) => {
            state.status = 'succeeded'
            entityAdapter.setAll(state, action.payload.map((v) => {return {id : v} as IRole} ))
        })
        builder.addCase(fetchUserRoles.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message || ''
        })
    }
})

export const { selectAll } = entityAdapter.getSelectors<RootState>(state => getSliceState(state))

export const selectStatus = (state: RootState) => getSliceState(state).status

export default userRolesSlice.reducer
