import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../../../store';
import { setDateFilterRange } from '../../../../store/actions/dateFilter/dateFiler';
import { selectDateFilter } from '../../../../store/selectors/dateFilter';
import { IDateRange } from '../../../../components/DateRangeSelector/IDateRange';

export const useDateRangeFilter = () => {
  const dispatch = useDispatch();

  const dateFilterRange = useTypedSelector(selectDateFilter);

  const dateRange = {
    dateRangeType: dateFilterRange.dateRangeType,
    start: new Date(dateFilterRange.start),
    end: new Date(dateFilterRange.end)
  } as IDateRange;

  const setDateRange = (range: IDateRange) => {
    const updatedDateFilterRange = {
      dateRangeType: range.dateRangeType,
      start: range.start.toISOString(),
      end: range.end.toISOString()
    };
    dispatch(setDateFilterRange(updatedDateFilterRange));
  };

  const onDateRangeChanged = (range: IDateRange) => {
    setDateRange({ ...range });
  };

  return { dateRange, dateFilterRange, onDateRangeChanged };
};
