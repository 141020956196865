import { AllRoles, AvailableRoles } from '../../services/UseRoles'

export interface IRoute {
  name: string;
  key: string;
  routePath: string;
  automationId: string;
  allowedRoles: (keyof typeof AvailableRoles)[];
}

export interface IRouteGroup {
  name: string;
  links: IRoute[];
  allowedRoles: (keyof typeof AvailableRoles)[];
}

export const routes: (IRoute | IRouteGroup)[] = [
  {
    name: "Monitoren",
    links: [
      {
        name: "Dashboard",
        key: "Dashboard",
        routePath: "/dashboard",
        automationId: "nav-dashboard",
        allowedRoles: AllRoles
      },
      {
        name: "Alle berichten",
        key: "Berichten",
        routePath: "/berichten",
        automationId: "nav-berichten",
        allowedRoles: ["Beheerder"]
      },
      {
        name: "Opdracht- & Statusberichten",
        key: "OpdrachtStatusBerichten",
        routePath: "/opdracht-statusberichten",
        automationId: "nav-Opdracht&StatusBerichten",
        allowedRoles: ["DagelijksOnderhoud", "Beheerder"]
      },
      {
        name: "Factuurberichten",
        key: "Factuurberichten",
        routePath: "/factuurberichten",
        automationId: "nav-Factuurberichten",
        allowedRoles: ["Beheerder", "Financieel"]
      }
    ],
    allowedRoles: AllRoles
  },
  {
    name: "Niet bezorgde berichten",
    links: [
      {
        name: "Alle berichten",
        key: "AlleBerichtenNietBezorgeBerichten",
        routePath: "/afhandelen/nietbezorgd",
        automationId: "nav-afhandelen-nietbezorgd",
        allowedRoles: ["Beheerder"]
      },
      {
        name: "Opdrachtberichten",
        key: "AfhandelenOpdrachtberichten",
        routePath: "/afhandelen/opdrachtberichten",
        automationId: "nav-afhandelen-opdrachtberichten",
        allowedRoles: ["Beheerder", "DagelijksOnderhoud"]
      },
      {
        name: "Statusberichten",
        key: "AfhandelenStatusberichten",
        routePath: "/afhandelen/statusberichten",
        automationId: "nav-afhandelen-statusberichten",
        allowedRoles: ["Beheerder", "DagelijksOnderhoud"]
      },
      {
        name: "Factuurberichten",
        key: "AfhandelenFactuurberichten",
        routePath: "/afhandelen/factuurberichten",
        automationId: "nav-afhandelen-factuurberichten",
        allowedRoles: ["Beheerder", "Financieel"]
      }
    ],
    allowedRoles: ["Beheerder", "DagelijksOnderhoud", "Financieel"]
  },
  {
    name: "Beheren",
    links: [
      {
        name: "Aannemers",
        key: "Aannemers",
        routePath: "/aannemers",
        automationId: "nav-aannemers",
        allowedRoles: ["Beheerder"]
      },
      {
        name: "Corporatie",
        key: "corporatie",
        routePath: "/corporatie",
        automationId: "nav-corporatie",
        allowedRoles: ["Beheerder"]
      },
      {
        name: "Business rules",
        key: "BusinessRules",
        routePath: "/businessrules",
        automationId: "nav-business_rules",
        allowedRoles: ["Beheerder"]
      },
      {
        name: "Mutatielog",
        key: "mutatielog",
        routePath: "/mutatielog",
        automationId: "nav-mutatielog",
        allowedRoles: ["Beheerder"]
      }
    ],
    allowedRoles: ["Beheerder"]
  }
];