import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  CommandBar,
  FontIcon,
  IColumn,
  ICommandBarItemProps,
  IconButton,
  Link,
  ResponsiveMode,
  Spinner,
  SpinnerSize,
  TooltipHost
} from "@fluentui/react";
import commandBarStyles from "styles/commandBarStyles";
import "./AfhandelenNietBezorgd.scss";
import { peekResult, useAppDispatch, useTypedSelector } from "store";
import { getBerichtBezorgingen, getUpdateHandmatigAfhandelen } from "store/selectors/berichtbezorgingen";
import { fetchBerichtbezorgingAfhandelenMetFilter } from "store/actions/berichtbezorging/data";
import HandmatigAfhandelenDialog from "./HandmatigAfhandelen/HandmatigAfhandelenDialog";
import { useHistory } from "react-router-dom";
import {
  AfhandelenHandmatigRequest,
  AfhandelenOpnieuwBezorgenRequest,
  BerichtBezorgingRequest,
  IBerichtbezorging
} from "interfaces/berichtbezorging";
import {
  afhandelenUpdatingSetPending,
  resetAfhandelenUpdating,
  updateHandmatigAfhandelen,
  updateOpnieuwBezorgen,
  updateOpnieuwBezorgenPerEmail
} from "store/actions/berichtbezorging/afhandelen";
import { EndpointInfoRequest } from "interfaces/EndpointInfoRequest";
import { EndpointType } from "enums/endpointType";
import EndpointInformation from "components/EndpointInformation/EndpointInformation";
import OhkModal from "components/OhkModal";
import { Zoeken } from "../BerichtBezorgingen/Zoeken/Zoeken";
import { BerichtZoekTerm } from "../BerichtBezorgingen/Zoeken/BerichtZoekTerm";
import { ZoekVelden } from "../BerichtBezorgingen/Zoeken/ZoekVelden";
import DateRangeSelector from "../../../components/DateRangeSelector/DateRangeSelector";
import BerichtBezorgingenTable from "../BerichtBezorgingen/BerichtbezorgingenTable/BerichtbezorgingenTable";
import { Berichtstatus } from "../../../enums/berichtStatus";
import {
  FilterDropdown,
  getDropdownOptions,
  iconStatusClass,
  statusFieldClass,
  statusIcon
} from "../BerichtBezorgingen/BerichtbezorgingenTable/utils";
import { getPropertyName } from "../../../lib/interfaceUtils";
import { stringToLocaleString } from "../../../lib/internationalization";
import { fetchBlob } from "../../../store/actions/blobs/data";
import { useDateRangeFilter } from "./Hooks/useDateRangeFilter";

interface AfhandelenNietBezorgdProps {
  pageHeading: "Niet bezorgde berichten" | "Factuurberichten" | "Statusberichten" | "Opdrachtberichten"
  fieldName?: keyof IBerichtbezorging
  columnTitle?: string
  columnPlaceholder?: string
  columnMinWidth?: number,
  columnMaxWidth?: number,
  filterFactuur?: boolean;
  filterOpdracht?: boolean
  filterStatus?: boolean
}

const AfhandelenNietBezorgd: React.FC<AfhandelenNietBezorgdProps> = ({
                                                                       fieldName = "key",
                                                                       columnPlaceholder,
                                                                       columnTitle = "",
                                                                       columnMinWidth = 0,
                                                                       columnMaxWidth = 0,
                                                                       filterFactuur,
                                                                       pageHeading,
                                                                       filterOpdracht,
                                                                       filterStatus
                                                                     }) => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [refetch, setRefetch] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const { list: items, status: fetchStatus } = useTypedSelector(getBerichtBezorgingen);
  const { status: updateStatus } = useTypedSelector(getUpdateHandmatigAfhandelen);
  // Afhandelen acties
  const [handmatigAfhandelenShowDialog, setHandmatigAfhandelenShowDialog] = useState(false);
  const [EndpointInfoRequest, setEndpointInfoRequest] = useState<EndpointInfoRequest>();
  const [spinnerText, setSpinnerText] = useState("Bezig met afhandelen, even geduld a.u.b. ...");
  const [zoekTermFilter, setZoekTermFilter] = useState<BerichtZoekTerm>({
    zoekTerm: "",
    veld: ZoekVelden.opdrachtnummer
  });
  const [filters, setFilters] = useState<Record<string, string | undefined>>({});

  const { dateRange, dateFilterRange, onDateRangeChanged } = useDateRangeFilter();

  const retrieveData = () => {
    const request = {
      datumVan: dateRange.start,
      datumTm: dateRange.end,
      filterFactuur: filterFactuur,
      filterOpdracht: filterOpdracht,
      filterStatus: filterStatus
    } as BerichtBezorgingRequest;
    var fetchPromise = dispatch(fetchBerichtbezorgingAfhandelenMetFilter(request));
    return () => {
      fetchPromise.abort();
    };
  };

  const filteredItems = useMemo(() => {
    return items
      .filter(item => (Berichtstatus.Geen === 0 || item.statusId === Berichtstatus.Geen))
      .filter((item) => !filters["status"] || item.status === filters["status"])
      .filter((item) => !filters["opdrachtStatus"] || item.opdrachtStatus === filters["opdrachtStatus"])
      .filter((item) => !filters["werkwijze"] || item.werkwijze === filters["werkwijze"])
      .filter((item) => !filters["soort"] || item.soort === filters["soort"])
      .filter((item) => !filters["van"] || item.van === filters["van"])
      .filter((item) => !filters["naar"] || item.naar === filters["naar"])
      .filter(item => {
        if (!zoekTermFilter?.zoekTerm?.trim()) return true;
        const term = zoekTermFilter.zoekTerm.trim().toLowerCase();
        return zoekTermFilter.veld === ZoekVelden.opdrachtnummer
          ? item.opdracht?.toLowerCase().includes(term)
          : true;
      });
  }, [items, zoekTermFilter, filters]);

  const handleResetFilters = () => {
    setFilters({});
  };

  useEffect(() => {
    setSpinnerText("Bezig met laden gegevens, even geduld a.u.b. ...");
    dispatch(resetAfhandelenUpdating());
    if (refetch) {
      setRefetch(false);
    }
  }, [dispatch, refetch]);

  useEffect(() => {
    retrieveData();
  }, [dispatch, dateFilterRange, refetch]);

  const handleHandmatigAfhandelen = useCallback((toelichting: string) => {
    setSpinnerText("Bezig met handmatig afhandelen, even geduld a.u.b. ...");
    dispatch(afhandelenUpdatingSetPending());
    if (selectedKeys.length > 0) {
      const selectedItems = items.filter(item => selectedKeys.includes(item.key));
      const bezorgingIds = selectedItems.map(item => item.bezorgingId);

      const request = {
        bezorgingIds,
        toelichting
      } as AfhandelenHandmatigRequest;

      dispatch(updateHandmatigAfhandelen({ request }))
        .then(peekResult)
        .then(() => {
          setRefetch(true);
        });

      setHandmatigAfhandelenShowDialog(false);
    }
  }, [dispatch, history, selectedKeys]);


  function getSelectedCount() {
    return selectedKeys.length;
  }

  function onOpnieuwBezorgen() {
    setSpinnerText("Bezig met opnieuw bezorgen, even geduld a.u.b. ...");
    dispatch(afhandelenUpdatingSetPending());
    if (selectedKeys.length > 0) {
      const selectedItems = items.filter(item => selectedKeys.includes(item.key));
      const bezorgingIds = selectedItems.map(item => item.bezorgingId);
      const request = {
        bezorgingIds
      } as AfhandelenOpnieuwBezorgenRequest;
      dispatch(updateOpnieuwBezorgen({ request }))
        .then(peekResult)
        .then(() => {
          setRefetch(true);
        });

    }
  }

  function onOpnieuwPerEmailBezorgen() {
    setSpinnerText("Bezig met email versturen en afhandelen, even geduld a.u.b. ...");
    dispatch(afhandelenUpdatingSetPending());
    if (selectedKeys.length > 0) {
      const selectedItems = items.filter(item => selectedKeys.includes(item.key));
      const bezorgingIds = selectedItems.map(item => item.bezorgingId);
      const request = {
        bezorgingIds
      } as AfhandelenOpnieuwBezorgenRequest;
      dispatch(updateOpnieuwBezorgenPerEmail({ request }))
        .then(peekResult)
        .then(() => {
          setRefetch(true);
        });

    }
  }

  function onHandmatigAfhandelen() {
    setHandmatigAfhandelenShowDialog(true);
  }

  function onSelectionChanged(keys: string[]) {
    setSelectedKeys(keys);
  }

  const handleBlobDownload = useCallback(
    (blobId: string | undefined, soort: "ontvangen-bericht" | "response-ontvangst" | "bezorgd-bericht" | "response-bezorging") => {
      if (!blobId) return;

      const fileName = `${soort}-${blobId}.xml`;
      dispatch(fetchBlob({ id: blobId, fileName })).then();
    },
    [dispatch]
  );

  function handleVanNaarClick(id: number, isVan: boolean) {
    if (isVan) {
      onEndpointVanClicked?.(id);
    } else {
      onEndpointNaarClicked?.(id);
    }
  }

  const commandBarItems: ICommandBarItemProps[] = useMemo(
    () => [
      {
        key: "pagetitle",
        onRender: () => (
          <span className="page-title">{pageHeading}</span>
        )
      }
    ],
    []
  );

  const commandBarItemsFar: ICommandBarItemProps[] = useMemo(
    () => {
      let items: ICommandBarItemProps[] = [];
      items.push(
        {
          key: "opnieuwBezorgen",
          text: "Opnieuw bezorgen",
          iconProps: { iconName: "Send", className: "icon" },
          split: false,
          ariaLabel: "Opnieuw bezorgen",
          disabled: getSelectedCount() === 0,
          onClick: () => onOpnieuwBezorgen()
        }
      );
      items.push({
        key: "handmatigAfhandelen",
        text: "Handmatig afhandelen",
        iconProps: { iconName: "EditContact", className: "icon" },
        split: false,
        ariaLabel: "Handmatig afhandelen",
        disabled: getSelectedCount() === 0,
        onClick: () => onHandmatigAfhandelen()
      });
      filterOpdracht &&
      items.push(
        {
          key: "opnieuwPerEmailBezorgen",
          text: "Email versturen en afhandelen",
          iconProps: { iconName: "MailForward", className: "icon" },
          split: false,
          ariaLabel: "Email versturen en afhandelen",
          disabled: getSelectedCount() === 0,
          onClick: () => onOpnieuwPerEmailBezorgen()
        }
      );
      items.push({
        key: "resetFilters",
        text: "Alle filters wissen",
        iconProps: { iconName: "clear", className: "icon" },
        split: false,
        ariaLabel: "Alle filters wissen",
        onClick: handleResetFilters
      });
      items.push({
        key: "refresh",
        text: "Vernieuwen",
        iconProps: { iconName: "Refresh", className: "icon" },
        split: false,
        ariaLabel: "Vernieuwen",
        onClick: () => setRefetch(true)
      });

      return items;
    },
    [getSelectedCount]
  );

  function onEndpointVanClicked(id: number): any {
    setEndpointInfoRequest({ endpointType: EndpointType.Ontvangst, endpointId: id });
  }

  function onEndpointNaarClicked(id: number): any {
    setEndpointInfoRequest({ endpointType: EndpointType.Bezorging, endpointId: id });
  }

  const handleFilterChange = (filterKey: string, selectedKey: string | undefined) => {
    setFilters((prevFilters) => {
      if (selectedKey === "0") {
        return {
          ...prevFilters,
          [filterKey]: ""
        };
      }

      return {
        ...prevFilters,
        [filterKey]: selectedKey
      };
    });
  };
  const opdrachtStatusOptions = useMemo(() => getDropdownOptions(items, "opdrachtStatus"), [items]);
  const werkwijzeOptions = useMemo(() => getDropdownOptions(items, "werkwijze"), [items]);
  const soortOptions = useMemo(() => getDropdownOptions(items, "soort"), [items]);
  const statusOptions = useMemo(() => getDropdownOptions(items, "status"), [items]);
  const vanOptions = useMemo(() => getDropdownOptions(items, "van"), [items]);
  const naarOptions = useMemo(() => getDropdownOptions(items, "naar"), [items]);

  const createColumn = (
    fieldName: keyof IBerichtbezorging,
    displayName: string,
    minWidth: number,
    maxWidth: number,
    placeholder?: string,
    onRender?: (item?: any, index?: number, column?: IColumn) => any
  ): IColumn => ({
    key: fieldName,
    name: displayName,
    fieldName: fieldName,
    minWidth: minWidth,
    maxWidth: maxWidth,
    isResizable: true,
    data: "string",
    onRender: onRender,
    onRenderHeader: placeholder ? () => (
      <FilterDropdown
        filterKey={fieldName}
        placeholder={placeholder}
        selectedKey={filters[fieldName]}
        options={getDropdownOptions(items, fieldName)}
        onFilterChange={handleFilterChange}
      />
    ) : undefined
  });

  const columns: IColumn[] = useMemo(() =>
      [
        {
          key: "iconStatus",
          name: "Status icon",
          className: "status-field",
          iconClassName: "header-status-field-icon",
          iconName: "Page",
          isIconOnly: true,
          minWidth: 24,
          maxWidth: 24,
          onRender: (item: IBerichtbezorging) => (
            <div className={statusFieldClass(item.statusId)}><FontIcon aria-label="status icon"
                                                                       iconName={statusIcon(item.statusId)}
                                                                       className={iconStatusClass} /></div>
          )
        },
        {
          key: getPropertyName<IBerichtbezorging>("ontvangen"),
          name: "Ontvangen",
          fieldName: getPropertyName<IBerichtbezorging>("ontvangen"),
          minWidth: 80,
          maxWidth: 100,
          isResizable: true,
          data: "date",
          onRender: (item: IBerichtbezorging) => (
            <span>{item.ontvangen && stringToLocaleString(item.ontvangen)}</span>
          )
        },
        {
          key: getPropertyName<IBerichtbezorging>("opdracht"),
          name: "Opdrachtnummer",
          fieldName: getPropertyName<IBerichtbezorging>("opdracht"),
          minWidth: 100,
          maxWidth: 140,
          isResizable: true,
          data: "string"
        },
        {
          key: getPropertyName<IBerichtbezorging>("opdrachtStatus"),
          name: "Opdrachtstatus",
          fieldName: getPropertyName<IBerichtbezorging>("opdrachtStatus"),
          minWidth: 160,
          maxWidth: 180,
          isResizable: true,
          data: "string",
          onRenderHeader: () => (
            <FilterDropdown
              filterKey="opdrachtStatus"
              placeholder="Opdrachtstatus"
              selectedKey={filters["opdrachtStatus"]}
              options={opdrachtStatusOptions}
              onFilterChange={handleFilterChange}
            />
          )
        },
        {
          key: getPropertyName<IBerichtbezorging>("soort"),
          name: "Soort",
          fieldName: getPropertyName<IBerichtbezorging>("soort"),
          minWidth: 150,
          maxWidth: 160,
          isResizable: true,
          data: "string",
          onRenderHeader: () => (
            <FilterDropdown
              filterKey="soort"
              placeholder="Soort"
              selectedKey={filters["soort"]}
              options={soortOptions}
              onFilterChange={handleFilterChange}
            />
          )
        },
        {
          key: getPropertyName<IBerichtbezorging>("werkwijze"),
          name: "Werkwijze",
          fieldName: getPropertyName<IBerichtbezorging>("werkwijze"),
          minWidth: 150,
          maxWidth: 160,
          isResizable: true,
          data: "string",
          onRenderHeader: () => (
            <FilterDropdown
              filterKey="werkwijze"
              placeholder="Werkwijze"
              selectedKey={filters["werkwijze"]}
              options={werkwijzeOptions}
              onFilterChange={handleFilterChange}
            />
          )
        },
        {
          key: getPropertyName<IBerichtbezorging>("status"),
          name: "Status",
          fieldName: getPropertyName<IBerichtbezorging>("status"),
          minWidth: 160,
          maxWidth: 150,
          isResizable: true,
          data: "string",
          onRenderHeader: () => (
            <FilterDropdown
              filterKey="status"
              placeholder="Status"
              selectedKey={filters["status"]}
              options={statusOptions}
              onFilterChange={handleFilterChange}
            />
          )
        },
        {
          key: getPropertyName<IBerichtbezorging>("laatsteMutatie"),
          name: "Laatste mutatie",
          fieldName: getPropertyName<IBerichtbezorging>("laatsteMutatie"),
          minWidth: 110,
          maxWidth: 130,
          isResizable: true,
          data: "date",
          onRender: (item: IBerichtbezorging) => (
            <span>{item.laatsteMutatie && stringToLocaleString(item.laatsteMutatie)}</span>
          )
        },
        {
          key: getPropertyName<IBerichtbezorging>("van"),
          name: "Van",
          fieldName: getPropertyName<IBerichtbezorging>("van"),
          minWidth: 150,
          maxWidth: 160,
          isResizable: true,
          data: "string",
          onRender: (item: IBerichtbezorging) => {
            const hasVanApp = !!item.vanId;
            return (
              <Link
                key={item.berichtId}
                onClick={() => (hasVanApp && !!item.vanId ? handleVanNaarClick(item.vanId, true) : undefined)}
              >{item.van}</Link>
            );
          },
          onRenderHeader: () => (
            <FilterDropdown
              filterKey="van"
              placeholder="Van"
              selectedKey={filters["van"]}
              options={vanOptions}
              onFilterChange={handleFilterChange}
            />
          )
        },
        {
          key: getPropertyName<IBerichtbezorging>("naar"),
          name: "Naar",
          fieldName: getPropertyName<IBerichtbezorging>("naar"),
          minWidth: 180,
          maxWidth: 200,
          isResizable: true,
          data: "string",
          onRender: (item: IBerichtbezorging) => {
            const hasNaarApp = item.naarId;
            return (
              <>
                {item.naarId && item.naarId > 0 ?
                  <Link
                    key={item.berichtId}
                    onClick={() => (hasNaarApp && !!item.naarId ? handleVanNaarClick(item.naarId, false) : undefined)}
                  >{item.naar}</Link>
                  :
                  <span>{item.naar}</span>
                }
              </>
            );
          },
          onRenderHeader: () => (
            <FilterDropdown
              filterKey="naar"
              placeholder="Naar"
              selectedKey={filters["naar"]}
              options={naarOptions}
              onFilterChange={handleFilterChange}
            />
          )
        },
        {
          key: getPropertyName<IBerichtbezorging>("toelichting"),
          name: "Toelichting",
          fieldName: getPropertyName<IBerichtbezorging>("toelichting"),
          minWidth: 80,
          maxWidth: 600,
          isResizable: true,
          data: "string",
          onRender: (item: IBerichtbezorging) => {
            return (
              <TooltipHost
                content={item.toelichting ?? ""}
                id="tooltip-toelichting"
              >
                <span>{item.toelichting}</span>
              </TooltipHost>
            );
          }
        },
        {
          key: "origineelbericht",
          name: "",
          minWidth: 20,
          maxWidth: 20,
          isResizable: false,
          onRender: (item: IBerichtbezorging) => (
            <div style={{ display: "flex", justifyContent: "center", alignContent: "center", height: "auto" }}>
              <IconButton
                styles={{
                  root: { height: "auto", ":active": { background: "#ffffff" } },
                  splitButtonContainer: { width: "auto" }
                }}
                iconProps={{ iconName: "MoreVertical" }}
                menuProps={{
                  items: [
                    {
                      key: "berichtinfo",
                      text: `Bericht: ${item.berichtId.toString()}-${item.bezorgingId.toString()}`,
                      disabled: true
                    },
                    {
                      key: "downloadontvangstbericht",
                      text: "Ontvangen bericht",
                      iconProps: { iconName: "Download" },
                      disabled: !item.ontvangenBerichtId,
                      onClick: () => handleBlobDownload(item.ontvangenBerichtId, "ontvangen-bericht")
                    },
                    {
                      key: "downloadontvangstResponse",
                      text: "Response ontvangst",
                      iconProps: { iconName: "Download" },
                      disabled: !item.ontvangenBerichtResponseId,
                      onClick: () => handleBlobDownload(item.ontvangenBerichtResponseId, "response-ontvangst")
                    },
                    {
                      key: "downloadbezorgdbericht",
                      text: "Bezorgd bericht",
                      iconProps: { iconName: "Download" },
                      disabled: !item.bezorgdBerichtId,
                      onClick: () => handleBlobDownload(item.bezorgdBerichtId, "bezorgd-bericht")
                    },
                    {
                      key: "downloadbezordResponse",
                      text: "Response bezorging",
                      iconProps: { iconName: "Download" },
                      disabled: !item.bezorgdBerichtResponseId,
                      onClick: () => handleBlobDownload(item.bezorgdBerichtResponseId, "response-bezorging")
                    },
                    {
                      key: "httpResponseCode",
                      text: item.bezorgingHttpStatusCode ? `HTTP response status code bezorging: ${item?.bezorgingHttpStatusCode}` : "",
                      disabled: true
                    }
                  ]
                }}
                hidden={true}
                menuIconProps={{ iconName: "" }}
              />
            </div>
          )
        }
      ],
    [handleVanNaarClick]);

  const factuurColumns: IColumn[] = useMemo(() =>
      [
        {
          key: "iconStatus",
          name: "Status icon",
          className: "status-field",
          iconClassName: "header-status-field-icon",
          iconName: "Page",
          isIconOnly: true,
          minWidth: 24,
          maxWidth: 24,
          onRender: (item: IBerichtbezorging) => (
            <div className={statusFieldClass(item.statusId)}><FontIcon aria-label="status icon"
                                                                       iconName={statusIcon(item.statusId)}
                                                                       className={iconStatusClass} /></div>
          )
        },
        {
          key: getPropertyName<IBerichtbezorging>("ontvangen"),
          name: "Ontvangen",
          fieldName: getPropertyName<IBerichtbezorging>("ontvangen"),
          minWidth: 80,
          maxWidth: 100,
          isResizable: true,
          data: "date",
          onRender: (item: IBerichtbezorging) => (
            <span>{item.ontvangen && stringToLocaleString(item.ontvangen)}</span>
          )
        },
        {
          key: getPropertyName<IBerichtbezorging>("opdracht"),
          name: "Opdrachtnummer",
          fieldName: getPropertyName<IBerichtbezorging>("opdracht"),
          minWidth: 100,
          maxWidth: 140,
          isResizable: true,
          data: "string"
        },
        createColumn(fieldName, columnTitle, columnMinWidth, columnMaxWidth, columnPlaceholder)
        ,
        {
          key: getPropertyName<IBerichtbezorging>("status"),
          name: "Status",
          fieldName: getPropertyName<IBerichtbezorging>("status"),
          minWidth: 160,
          maxWidth: 150,
          isResizable: true,
          data: "string",
          onRenderHeader: () => (
            <FilterDropdown
              filterKey="status"
              placeholder="Status"
              selectedKey={filters["status"]}
              options={statusOptions}
              onFilterChange={handleFilterChange}
            />
          )
        },
        {
          key: getPropertyName<IBerichtbezorging>("laatsteMutatie"),
          name: "Laatste mutatie",
          fieldName: getPropertyName<IBerichtbezorging>("laatsteMutatie"),
          minWidth: 110,
          maxWidth: 130,
          isResizable: true,
          data: "date",
          onRender: (item: IBerichtbezorging) => (
            <span>{item.laatsteMutatie && stringToLocaleString(item.laatsteMutatie)}</span>
          )
        },
        {
          key: getPropertyName<IBerichtbezorging>("van"),
          name: "Van",
          fieldName: getPropertyName<IBerichtbezorging>("van"),
          minWidth: 150,
          maxWidth: 160,
          isResizable: true,
          data: "string",
          onRender: (item: IBerichtbezorging) => {
            const hasVanApp = !!item.vanId;
            return (
              <Link
                key={item.berichtId}
                onClick={() => (hasVanApp && !!item.vanId ? handleVanNaarClick(item.vanId, true) : undefined)}
              >{item.van}</Link>
            );
          },
          onRenderHeader: () => (
            <FilterDropdown
              filterKey="van"
              placeholder="Van"
              selectedKey={filters["van"]}
              options={vanOptions}
              onFilterChange={handleFilterChange}
            />
          )
        },
        {
          key: getPropertyName<IBerichtbezorging>("naar"),
          name: "Naar",
          fieldName: getPropertyName<IBerichtbezorging>("naar"),
          minWidth: 180,
          maxWidth: 200,
          isResizable: true,
          data: "string",
          onRender: (item: IBerichtbezorging) => {
            const hasNaarApp = item.naarId;
            return (
              <>
                {item.naarId && item.naarId > 0 ?
                  <Link
                    key={item.berichtId}
                    onClick={() => (hasNaarApp && !!item.naarId ? handleVanNaarClick(item.naarId, false) : undefined)}
                  >{item.naar}</Link>
                  :
                  <span>{item.naar}</span>
                }
              </>
            );
          },
          onRenderHeader: () => (
            <FilterDropdown
              filterKey="naar"
              placeholder="Naar"
              selectedKey={filters["naar"]}
              options={naarOptions}
              onFilterChange={handleFilterChange}
            />
          )
        },
        {
          key: getPropertyName<IBerichtbezorging>("toelichting"),
          name: "Toelichting",
          fieldName: getPropertyName<IBerichtbezorging>("toelichting"),
          minWidth: 80,
          maxWidth: 600,
          isResizable: true,
          data: "string",
          onRender: (item: IBerichtbezorging) => {
            return (
              <TooltipHost
                content={item.toelichting ?? ""}
                id="tooltip-toelichting"
              >
                <span>{item.toelichting}</span>
              </TooltipHost>
            );
          }
        },
        {
          key: "origineelbericht",
          name: "",
          minWidth: 20,
          maxWidth: 20,
          isResizable: false,
          onRender: (item: IBerichtbezorging) => (
            <div style={{ display: "flex", justifyContent: "center", alignContent: "center", height: "auto" }}>
              <IconButton
                styles={{
                  root: { height: "auto", ":active": { background: "#ffffff" } },
                  splitButtonContainer: { width: "auto" }
                }}
                iconProps={{ iconName: "MoreVertical" }}
                menuProps={{
                  items: [
                    {
                      key: "berichtinfo",
                      text: `Bericht: ${item.berichtId.toString()}-${item.bezorgingId.toString()}`,
                      disabled: true
                    },
                    {
                      key: "downloadontvangstbericht",
                      text: "Ontvangen bericht",
                      iconProps: { iconName: "Download" },
                      disabled: !item.ontvangenBerichtId,
                      onClick: () => handleBlobDownload(item.ontvangenBerichtId, "ontvangen-bericht")
                    },
                    {
                      key: "downloadontvangstResponse",
                      text: "Response ontvangst",
                      iconProps: { iconName: "Download" },
                      disabled: !item.ontvangenBerichtResponseId,
                      onClick: () => handleBlobDownload(item.ontvangenBerichtResponseId, "response-ontvangst")
                    },
                    {
                      key: "downloadbezorgdbericht",
                      text: "Bezorgd bericht",
                      iconProps: { iconName: "Download" },
                      disabled: !item.bezorgdBerichtId,
                      onClick: () => handleBlobDownload(item.bezorgdBerichtId, "bezorgd-bericht")
                    },
                    {
                      key: "downloadbezordResponse",
                      text: "Response bezorging",
                      iconProps: { iconName: "Download" },
                      disabled: !item.bezorgdBerichtResponseId,
                      onClick: () => handleBlobDownload(item.bezorgdBerichtResponseId, "response-bezorging")
                    },
                    {
                      key: "httpResponseCode",
                      text: item.bezorgingHttpStatusCode ? `HTTP response status code bezorging: ${item?.bezorgingHttpStatusCode}` : "",
                      disabled: true
                    }
                  ]
                }}
                hidden={true}
                menuIconProps={{ iconName: "" }}
              />
            </div>
          )
        }
      ],
    [handleVanNaarClick]);

  return (
    <div className="afhandelen flex-grow flex-container flex-column">
      {updateStatus === "pending"
        ? (<div className="afhandelen-spinner"><Spinner size={SpinnerSize.large} label={spinnerText} /></div>)
        : (
          <div className="afhandelen-content flex-grow flex-container flex-column">
            <div className="afhandelen-content-action"><CommandBar items={commandBarItems} farItems={commandBarItemsFar}
                                                                   styles={commandBarStyles} /></div>
            <div className="berichtbezorgingen-header-actions-top flex-container flex-gap-16">
              <DateRangeSelector dateRange={dateRange} onDateRangeChange={onDateRangeChanged}></DateRangeSelector>
              <Zoeken zoekTerm={zoekTermFilter}
                      onZoekTermChanged={zoekTermChanged => setZoekTermFilter(zoekTermChanged)}></Zoeken>
            </div>
            <div className="afhandelen-content-table flex-grow">
              <BerichtBezorgingenTable items={filteredItems}
                                       fetchStatus={fetchStatus}
                                       onSelectionChanged={onSelectionChanged}
                                       columns={pageHeading === "Niet bezorgde berichten" ? columns : factuurColumns}
              />
            </div>
          </div>
        )}
      {handmatigAfhandelenShowDialog && (
        <HandmatigAfhandelenDialog
          title="Handmatig afhandelen"
          subText={[
            `Weet u zeker dat u de geselecteerde berichten wilt markeren als handmatige afgehandeld?`,
            `Er zijn ${getSelectedCount()} berichten geselecteerd.`
          ]}
          onCancel={() => setHandmatigAfhandelenShowDialog(false)}
          onOk={handleHandmatigAfhandelen}
        />
      )}
      {EndpointInfoRequest &&
        <OhkModal title="Endpoint informatie"
                  isOpen={!!EndpointInfoRequest}
                  onClose={() => setEndpointInfoRequest(undefined)}
                  onDismiss={() => setEndpointInfoRequest(undefined)}
                  responsiveMode={ResponsiveMode.large}>
          <EndpointInformation endpointInfoRequest={EndpointInfoRequest}></EndpointInformation>
        </OhkModal>
      }
    </div>
  );
};

export default AfhandelenNietBezorgd;