import { Dropdown, IDropdownOption, IDropdownStyles, mergeStyles } from '@fluentui/react'
import React from 'react'
import { Constants } from '../../../../lib/constants/icons'
import { IBerichtbezorging } from '../../../../interfaces/berichtbezorging'

const dropdownStyles: Partial<IDropdownStyles> = {
  root: { paddingTop: 7 },
  dropdownItemHeader: { border: 0 },
  title: {
    border: 0,
    ':focus': { backgroundColor: '#f3f2f1', border: 0 },
    ':hover': { backgroundColor: '#f3f2f1' }
  },
  dropdownItemSelected: {
    border: 0,
    paddingLeft: 0,
    ':focus': { backgroundColor: '#f3f2f1', border: 0 },
    ':hover': { backgroundColor: '#f3f2f1' }
  },
  dropdown: {
    border: 0,
    ':focus': { backgroundColor: '#f3f2f1', border: 0 },
    ':hover': { border: 0, backgroundColor: '#f3f2f1' }
  },
  dropdownItems: {
    border: '1px solid #f3f2f1',
    boxShadow: '#f3f2f1 0px 0px 9px -1px'
  }
}

export const iconStatusClass = mergeStyles({
  fontSize: 16,
  height: 16,
  width: 16,
  margin: '0 4px'
})

type FilterDropdownProps = {
  filterKey: string;
  placeholder: string;
  selectedKey: string | undefined;
  options: IDropdownOption[];
  onFilterChange: (filterKey: string, selectedKey: string | undefined) => void;
};

export const statusIcon = (statusId: number): string => {
  switch (statusId) {
    case 1:
      return Constants.IconBerichtStatusOnbestelbaar
    case 2:
      return Constants.IconBerichtStatusInBehandeling
    case 3:
      //StatusCircleCheckmark
      return Constants.IconBerichtStatusBezorgd
    case 4:
      return Constants.IconBerichtStatusNietBezorgd
    case 5:
      return Constants.IconBerichtStatusHandmatigAfgehandeld
    default:
      return Constants.IconBerichtStatusHOnbekend
  }
}

export const statusFieldClass = (statusId: number): string => {
  return `status-field status-field-${statusId}`
}


export const getDropdownOptions = (data: IBerichtbezorging[], key: keyof IBerichtbezorging): IDropdownOption[] => {
  const uniqueValues = Array.from(new Set(data.map(item => item[key])))

  return [
    { key: 0, text: 'Filter wissen' },
    ...uniqueValues
      .filter((value): value is string | number => value !== null && value !== undefined && value !== '' && !(value instanceof Date))
      .map(value => ({ key: value, text: String(value) }))
  ]
}

export const FilterDropdown: React.FC<FilterDropdownProps> = ({
                                                                filterKey,
                                                                placeholder,
                                                                selectedKey,
                                                                options,
                                                                onFilterChange
                                                              }) => (
  <Dropdown
    placeholder={placeholder}
    selectedKey={selectedKey || ''}
    options={options}
    calloutProps={{ style: { width: 'min-content' }, calloutWidth: 0 }}
    onRenderTitle={(props) => (
      <span>
        {props?.[0]?.text === selectedKey
          ? `${placeholder} "${props?.[0]?.text || ''}"`
          : placeholder}
      </span>
    )}
    onChange={(event, option) => onFilterChange(filterKey, option?.key?.toString() || undefined)}
    onClick={(e) => e.stopPropagation()}
    styles={dropdownStyles}
  />
)
