import { combineReducers } from '@reduxjs/toolkit'
import auth from './auth'
import configuration from './configuration'
import genericError from './genericError'
import me from './me'
import roles from "./roles";

export default combineReducers({
  auth,
  configuration,
  genericError,
  me,
  roles
})
